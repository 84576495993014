<template>
  <service-component :code="this.$route.params.code"></service-component>
</template>

<script>
import ServiceComponent from "../../components/introduce/ServiceComponent.vue";
export default {
  components: { ServiceComponent },
};
</script>

<style></style>
