<template>
  <v-container fluid>
    <v-row no-gutters justify="center">
      <v-col cols="12" md="7" lg="7" xl="7">
        <v-img src="@/assets/images/user_guide/material.png"> </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
