<template>
  <v-container fluid class="ma-0 pa-0">
    <service-tab-1 v-if="code === '1'"></service-tab-1>
    <service-tab-2 v-else-if="code === '2'"></service-tab-2>
    <service-tab-3 v-else-if="code === '3'"></service-tab-3>
    <service-tab-4 v-else-if="code === '4'"></service-tab-4>
  </v-container>
</template>

<script>
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import ServiceTab4 from "./serviceTab/ServiceTab4.vue";
import ServiceTab3 from "./serviceTab/ServiceTab3.vue";
import ServiceTab2 from "./serviceTab/ServiceTab2.vue";
import ServiceTab1 from "./serviceTab/ServiceTab1.vue";

export default {
  components: { ServiceTab4, ServiceTab3, ServiceTab2, ServiceTab1 },
  props: {
    code: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      sideSet: {
        menuName: "introduce",
        sideTitle: "회사소개",
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {},
  computed: {},
  methods: {},
  destroyed() {},
};
</script>

<style lang="scss" scoped></style>
